@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.actions-list {
  position: absolute;
  top: 35px;
  right: calc(100% + 28px);
  width: auto;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-item {
    width: 34.1px;
    height: 28.6px;
    object-fit: contain;
    margin-left: 13.7px;
    cursor: pointer;

    &--hide {
      display: none;
    }
  }
}
