@import '../../assets/styles/variables';

.screen-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.78;
  z-index: 2;

  &-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 24px;
      font-weight: normal;
      color: $brand-default;
    }
  }
}


@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  padding-top: 2.5px;
  padding-left: 5px;

  &-dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: $brand-bg;
    border-radius: 10px;
    display: inline-block;
    height: 10px;
    width: 10px;

    &:nth-child(2) {
      animation-delay: .5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}