@import "variables";

/**
  Here are the basic settings for the project.
 */

*:focus {
  outline: none;
}

textarea:focus, input:focus{
  outline: none;
}

button:disabled {
  opacity: .5;
  cursor: not-allowed !important;
}

button + button {
  margin-left: .5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}

.input-feedback-wrapper {
  position: relative;
}

.input-feedback {
  position: absolute;
  top: -24px;
}

input.error, textarea.error {
  background: none;
  border: 1px solid red;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: .3rem;
}

.input-feedback {
  color: red;
  margin-top: .25rem;
  font-size: 13px;
}

.app-error {
  background-color: $brand-primary;
  color: white;
  font-weight: bold;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 200px;

  .comiserate {
    font-size: 160px;
    line-height: 160px;
    margin-bottom: 40px;
  }

  h1 {
    font-weight: 900;
    font-size: 38px;
    margin-bottom: 30px;
  }

  p {
    font-size: 18px;
  }

  a {
    color: $brand-sand;
    font-weight: bold;
    text-decoration: underline;
  }
}

.m-t-10 { margin-top: 10px; }
.m-r-10 { margin-right: 10px; }
.m-l-10 { margin-left: 10px; }
.m-b-10 { margin-bottom: 10px; }

.m-t-20-5 { margin-top: 20.5px; }
.m-r-11 { margin-right: 11px; }

.cross-icon {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  cursor: pointer;

  &::after, &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $cross-icon-color;
    transition: background-color 300ms ease-in-out;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    &::after, &::before {
      background-color: $brand-sapphire-color;
    }
  }
}

.check-icon {
  position: relative;
  display: block;
  width: 10px;
  height: 12px;

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 50%;
    border-left: 2px solid $check-green;
    border-bottom: 2px solid $check-green;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
