@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

$btn-text-font-size: 16px;

.btn {
  @include button-size(55px, 154px, 16px, 58px, 3px);
  border: none;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: normal;
  font-weight: bold;
  font-size: $btn-text-font-size;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  text-align: center;
  color: #ffffff;

  &.btn-sm {
    @include button-size(24.2px, 101.3px, 10px, 1.3, 3px);
    text-transform: capitalize;
    font-weight: normal;
  }

  &.btn-s {
    @include button-size(24.2px, 116.3px, 10px, 1.3, 3px);
    text-transform: capitalize;
    font-weight: normal;
  }

  &.btn-xm {
    @include button-size(55px, 219px, 16px, 55px, 3px);
  }

  &.btn-flexible {
    @include button-size(auto, auto, 10px, 1.3, 3px);
    padding: 6px 21px 5px 17px;
    text-transform: capitalize;
    font-weight: normal;
    white-space: nowrap;
  }

  &-right {
    float: right;
  }
}


.btn-primary{
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

.btn-sole{
  @include button-variant($btn-sole-color, $btn-sole-bg, $btn-sole-border);
}

.btn-sapphire {
  @include button-variant($brand-sapphire-color, $brand-sapphire-bg, $brand-sapphire-border);
}

.sign-out-from-map {
  z-index: 9999;
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.upload-button {
  cursor: pointer;
  width: 209px;
  height: 38px;
  padding-right: 5px;
  line-height: 38px;

  .upload-button-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -100000000;
  }
}

.btn-text {
  width: 116px;
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

