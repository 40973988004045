@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.edit-avatar {
  position: relative;
  display: block;
  margin-top: 27px;

  &__header {
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.31;
    color: $brand-default;
    margin-bottom: 9px;
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .upload-button {
      width: 209px;
      height: 38px;
      border-radius: 3px;
      margin: 0 0 0 30px;

      @media screen and (max-width: 1240px) {
        width: 159px;
        height: 35px;
        line-height: 35px;

        .file-text {
          font-size: 11px;
          height: 11px;
          width: auto;
          line-height: 1;
        }
      }
    }
  }

  &__img {
    width: 106px;
    min-width: 106px;
    height: 106px;
    border-radius: 50%;
    overflow: hidden;

    @media screen and (max-width: 1240px) {
      width: 80px;
      min-width: 80px;
      height: 80px;
    }
  }
}
