.fade-appear {
  opacity: 0.3;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 350ms ease-in-out;
}
