@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.applications-menu {
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-header {
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 15px;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    border-bottom: 1px solid $border-color;
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &-focus-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-data-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.applications-focus {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  min-height: 1vh;
  max-height: 20vh;

  @include breakpoint(ipadPortrait) {
    max-height: 27vh;
  }

  @include breakpoint(ipadLandscape) {
    max-height: 21vh;
  }

  &-item {
    padding: 12px 6px 11px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
  }

  &-img {
    &-check {
      width: 13.9px;
      height: 13.9px;
    }
    &-lock {
      width: 12px;
      height: 11px;
    }
  }
}

.applications-data {
  max-height: 48.2vh;
  min-height: 44.2vh;

  @include breakpoint(ipadLandscape) {
    max-height: 44vh;
  }
}

