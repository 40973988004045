.layers-list {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  padding-left: 32px;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: -50px;
    left: -90px;
    width: 100%;
    height: 120%;
    background-image: linear-gradient(to bottom right, black, transparent);
    z-index: -1;
    filter: blur(35px);
  }

  &__title {
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: white;
    margin-bottom: 16px;
  }
}
