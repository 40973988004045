@import '../../assets/styles/variables';
@import "../../assets/styles/mixins";

.profile-edit-form {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  min-height: 100%;
  box-sizing: border-box;
}

.profile-edit {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
  min-height: 100%;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .sub-menu-header {
    display: block;
    margin-bottom: 45px;

    @media screen and (max-width: 1240px) {
      margin-bottom: 25px;
    }
  }

  .sub-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    text-align: left;
    color: $brand-default;
    margin-bottom: 30px;

    @media screen and (max-width: 1240px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  &__wrapper {
    display: block;
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  &__footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    @media screen and (max-width: 1240px) {
      .btn {
        width: 150px;
        height: 45px;
        font-size: 15px;
        line-height: 1;
      }
    }
  }
}
