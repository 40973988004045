@import "../../../assets/styles/variables";

.timeline-control-btn {
  opacity: 0.3;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  transition: opacity 300ms ease-in-out;
  cursor: pointer;

  &--active {
    opacity: 1;
  }
}

.timeline-control {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  &__play-btn {
    width: 15px;
    margin-right: 21px;
  }

  &__pause-btn {
    width: 13px;
    margin-right: 20px;
  }

  &__loop-btn {
    width: 32px;
    margin-right: 30px;
  }
}
