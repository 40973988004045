@import "../../../assets/styles/variables";

.select-fish-layer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__select-box {
    display: inline-block;
    width: 167px;
    left: -27px;
    z-index: 2;
  }

  .select-fish-layer {
    &__indicators {
      display: none;
    }

    &__menu {
      max-height: unset;
      margin: 5px 0;
      border-radius: 4px;
      background-color: $brand-sapphire-color;
      overflow: hidden;
      z-index: 2;
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      font-size: 14px;
      cursor: pointer;

      &--is-focused {
        color: $brand-sapphire-color;
        background-color: $cross-icon-color;
      }

      &--is-selected  {
        color: $brand-sapphire-color;
        background-color: $brand-default;
      }
    }

    &__control {
      width: 100%;
      height: 24px;
      min-height: 24px;
      padding-left: 17px;
      border-radius: 20px;
      background-color: transparent;
      box-shadow: none;
      cursor: pointer;

      &--is-focused, &:hover {
        border-color: $brand-sapphire-color;
        box-shadow: none;
      }
    }

    &__value-container {
      position: relative;
      max-height: 100%;
      flex: auto;

      &::after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        right: 11px;
        transform: translate(0, -50%);
        margin-top: 4px;

        border: 4px solid $brand-sapphire-color;
        border-top-width: 6px;
        border-bottom-width: 6px;

        border-bottom-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
      }
    }

    &__single-value {
      color: $brand-sapphire-color;
      font-size: 14px;
      line-height: 1;

      & ~ div {
        margin: 0;
      }
    }
  }
}
