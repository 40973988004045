@import '../../assets/styles/variables';

.profile-menu {
  display: flex;
  flex-direction: column;
  color: #fff;

  &-greet {
    font-size: 14px;
    line-height: 1.36;
    font-weight: normal;
    margin-bottom: 3px;
  }

  &-name {
    display: block;
    word-wrap: break-word;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.32;
    margin-bottom: 9px;
  }

  &-email {
    opacity: 0.5;
    line-height: 1.3;
    font-size: 10px;
    font-weight: normal;
  }

  &-header {
    padding-bottom: 31.5px;
    border-bottom: 1px solid $brand-smoke;
    color: #fff;
    display: flex;
    flex-direction: column;

    span {
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
    }
  }

  &-body {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      padding: 10px 0 12px;
      border-bottom: 1px solid $brand-smoke;
      cursor: pointer;

      &:hover, &:active {
        padding: 10px 0 12px 10px;
        background-color: $brand-primary;
      }
    }

    .active-sub-link {
      padding: 10px 0 12px 10px;
      text-decoration: none;
      background: rgba(0, 177, 255, 0.3);
    }
  }

  .sign-out-button {
    display: flex;
    position: absolute;
    bottom: 34px;
    left: 26px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    cursor: pointer;

    &:hover {
      color: $brand-smoke;
    }

    &:hover {
      .sign-out-icon {
        display: none;
      }
      .icon-hover {
        display: block;
      }
    }

    .sign-out-icon {
      width: 19px;
      height: 20.4px;
      margin-right: 8.6px;
      display: none;
    }

    .bg-icon {
      display: block;
    }
  }
}
