@import '../../../assets/styles/variables';

.snapshot {
  &-item-wrapper {
    width: 93.6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 3px;
  }

  &-item {
    width: 93.6px;
    height: 103px;
    margin: 15px 0;

    &-close {
      width: 18.6px;
      float: right;
      position: relative;
      top: 0;
      cursor: pointer;
    }

    &-image {
      width: 93.6px;
      height: 93.6px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.47);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &-buttons-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 15.2px;
      width: 100%;
    }
  }
}

.snapshot-link {
  height: inherit;
  cursor: pointer;
  margin-left: 16.7px;
}

.download-icon {
  width: 13.3px;
  height: 15.2px;
  object-fit: contain;
}

.print-icon {
  height: 14.6px;
  width: 14.6px;
  object-fit: contain;
}
