@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.waypoints-accordion {
  position: absolute;
  display: block;
  width: 362px;
  height: auto;
  right: calc(100% + 35px);
  bottom: 35px;
  border-radius: 3px;
  box-shadow: 0 3px 10px 0 $black;
  background-color: $brand-sapphire-color;
  overflow: hidden;

  @include breakpoint(tablet) {
    width: 289.6px;
    right: calc(100% + 28px);
    bottom: 28px;
    box-shadow: 0 3px 8px 0 $black;
  }
}

.nautical-miles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 13px 16px 16px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.8);
  background-color: $nautical-miles;

  @include breakpoint(tablet) {
    padding: 16px 10.4px 12.8px 12.8px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.8);
  }

  &__value, &__button {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: $brand-sapphire-color;

    @include breakpoint(tablet) {
      font-size: 8px;
    }
  }

  &__value {
    white-space: nowrap;
  }

  &__button {
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
}

/**
  Maybe we add it to accordion
 */
.slide-accordion {
  overflow: hidden;
}

.slide-accordion-enter {
  height: auto;
  max-height: 0;
}

.slide-accordion-enter.slide-accordion-enter-active {
  max-height: 500px;
  transition: max-height 800ms ease;
}

.slide-accordion-leave {
  max-height: 500px;
}

.slide-accordion-leave.slide-accordion-leave-active {
  max-height: 0;
  transition: max-height 800ms ease;
}
