@import "../../assets/styles/variables";

.timeline {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  left: 100%;
  bottom: 0;
  width: 962px;
  height: 104px;
  background-color: rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  z-index: 1;

  @media screen and (max-width: $timeline-max-tablet-width) {
    width: auto;
    height: 64px;
  }

  &__wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 8px;
    padding-left: 51px;
    padding-right: 32px;
    box-sizing: border-box;

    @media screen and (max-width: $timeline-max-tablet-width) {
      position: relative;
      padding-left: 32px;
      padding-top: 0;
    }
  }
}
