@import "../../assets/styles/variables";

.waypoints-menu {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 100%;

  &__header {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 0 15px;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: $brand-sapphire-color;
    border-bottom: 1px solid $border-color;
  }

  &__simple-bar {
    height: auto;
    max-height: calc(100% - 69px);
  }

  &__body {
    display: block;
    max-height: 100%;
    overflow: hidden;
  }

  &__footer {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 18px;
  }
}
