@import "../../../assets/styles/variables";

.caret {
  width: 14.3px;
  height: 9.2px;
  object-fit: contain;
  padding-right: 5px;

  &-up {
    transform: rotate(180deg);
  }
}

.form-select {
  margin-bottom: 20px;
}

.select-input {
  transition: 0.4s;
  border: 1px solid $brand-sand;
  background: $brand-sand;
  height: $input-height;
  line-height: $input-height;
  color: $brand-default;

  &__placeholder {
    color: $brand-default !important;
    font-size: 16px;
    padding-left: 5px;
  }

  &__menu {
    font-size: 16px;
    top: 44px !important;
    border-top-color: transparent !important;
    border: 1px solid $brand-sand;
  }

  &__single-value {
    font-size: 16px;
    padding-left: 5px;

    &--is-disabled {
      color: $brand-sand !important;
    }
  }

  &__control {
    border-radius: $rounded !important;
    border: 1px solid  $brand-sand !important;
    height: $input-height + 2px;
    font-size: 18px;
    line-height: $input-height;

    &:hover,
    &-focused,
    &:focus-within {
      border-color: $brand-sand !important;
      box-shadow: none !important;
    }

    &--is-disabled {
      background-color: $brand-sand !important;
    }

    &--is-focused {
      box-shadow: none !important;
    }
  }

  &__indicator {
    cursor: pointer;
    margin: 0 4px;
    text-align: center;

    &-separator {
      width: 0 !important;
    }
  }
}

.form-select-dark {
  margin-bottom: 5px;
  margin-left: -1px;
  width: 167px;

  label {
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 10.2px;
  }

  .select-input {
    background: $brand-default;
    height: 24px;
    line-height: 24px;
    color: #fff;

    &__placeholder {
      color: #fff !important;
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: left;
      padding-left: 0;
    }

    &__menu {
      color: $brand-sole;
      font-size: 10px;
      position: absolute !important;
      background: $brand-dark;
      top: auto !important;
      bottom: calc(100% - 8px) !important;
      padding: 0 !important;
    }

    &__single-value {
      font-size: 10px;
      padding-left: 5px;

      &--is-disabled {
        color: $brand-sand !important;
      }
    }

    &__control {
      border-radius: 20px !important;
      border: 1px solid  $brand-sand !important;
      background: $brand-dark;
      color: #fff;
      height: 24px;
      min-height: 24px;
      font-size: 18px;
      line-height: 24px;

      &:hover,
      &-focused,
      &:focus-within {
        box-shadow: none !important;
      }

      &--is-disabled {
        background-color: $brand-dark !important;
      }

      &--is-focused {
        box-shadow: none !important;
      }
    }

    &__indicator {
      cursor: pointer;
      margin: 0 0 0 15px;
      text-align: center;

      .caret {
        width: 8.4px;
        height: 6.3px;
        margin-right: -2px;
      }

      .caret-up {
        margin-right: 4px;
      }

      &-separator {
        width: 0 !important;
      }
    }
  }
}
