@import "../../assets/styles/variables";

// 80px is width for sidebar
.leaflet-container {
  position: fixed;
  display: block;
  top: 0;
  left: 80px;
  width: calc(100% - 80px);
  height: 100%;
  z-index: 0;

  .leaflet-control-zoom {
    position: fixed;
    top: 10px;
    right: 10px;
    margin: 0;
  }

  .leaflet-map-pane {
    canvas {
      z-index: 100;

      &.heatmap-layer {
        filter: blur(15px);
      }

      &.contour-layer {
        z-index: 101;
      }

      &.wind-layer {
        z-index: 102;
      }

      &.fish-layer {
        z-index: 103;
      }

      &.waypoints-layer {
        z-index: 104;
      }
    }
  }
}
