@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.profile-settings {
  position: relative;
  display: block;
  width: 50%;
  max-width: 574px;
  padding-right: 74px;

  @media screen and (max-width: 1240px) {
    padding-right: 34px;
  }

  .form-select label {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.31;
    color: $brand-default;

    @media screen and (max-width: 1240px) {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1240px) {
    .select-input__control {
      font-size: 14px;
      margin-bottom: 17px;
      height: 40px;
    }
  }
}
