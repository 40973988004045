@import '../../assets/styles/variables';

.profile-menu-screen {
  position: fixed;
  display: block;
  top: 0;
  left: 329px;
  width: calc(100% - 329px);
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  z-index: 2;

  &__scroll-bar {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: auto;
    min-height: 100%;
    box-sizing: border-box;
    padding: 49px 38.5px 53px 55px;

    @media screen and (max-width: 1240px) {
      padding: 40px 30px 43px 45px;
    }

    .close {
      position: absolute;
      width: 21px;
      height: 21px;
      float: right;
      right: 31px;
      top: 25px;
      margin: 0;
      padding: 0;
      overflow: hidden;
      border-radius: 2px;
      border: solid 2px #fff;
      transition: all 0.3s ease-out;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      &::before,
      &::after {
        content: ' ';
        position: absolute;
        left: 7px;
        height: 21px;
        width: 2px;
        background-color: $brand-default;
      }

      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
