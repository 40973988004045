@import "../../assets/styles/variables";

.zoom-control {
  position: absolute;
  display: block;
  top: 54px;
  right: calc(100% + 42px);
  text-align: center;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &__slider {
    position: relative;
    display: inline-block;
    margin: 49px 0 0 1px;
    padding: 0;

    .rc-slider-vertical {
      width: 8px;
      height: 180px;
      padding: 0;
    }

    .rc-slider-track {
      display: none;
    }

    .rc-slider-rail {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: $zoomControlSliderCorol;
    }

    .rc-slider-handle {
      width: 18px;
      height: 18px;
      margin-left: -5px;
      margin-top: 0;
      margin-bottom: -9px;
      border-radius: 50%;
      border: 1px solid $zoomControlSliderBorder;
      background-color: $zoomControlSliderCorol;

      &:focus {
        outline: none;
        box-shadow: none;
        transition: none;
      }
    }
  }

  &__zoom-in, &__zoom-out {
    position: absolute;
    padding: 0;
    border: none;
    background-color: transparent;
    font-family: HelveticaNeue, "Helvetica Neue", Roboto, "Roboto", Arial, sans-serif;
    font-size: 21px;
    line-height: 1.19;
    color: $zoomControlColor;
    transform: rotate(90deg);
  }

  &__zoom-in {
    top: -6px;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  &__zoom-out {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
}
