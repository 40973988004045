@import '../assets/styles/variables';
@import "../assets/styles/mixins";

.app {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  &__left-block,
  &__right-block {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    top: 0;
    width: auto;
    height: 100%;
  }

  &__left-block {
    left: 0;
    z-index: 2;
  }

  &__right-block {
    right: 0;
    z-index: 1;
  }
}

.loading-cursor {
  cursor: progress !important;
}

.simplebar-track {
  right: -3px;
}

.simplebar-scrollbar:before {
  background: $brand-sapphire !important;
}
