@import "../../../assets/styles/variables";

.layers-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12.3px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__image {
    position: relative;
    display: block;
    width: 26px;
    height: 26px;
    margin-right: 9.4px;
    background-color: $brand-default;
    border: 2px solid white;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 1;

    &--fish {
      z-index: 3;
    }

    &--active {
      background-color: $brand-primary;
    }

    &--loading::before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
      border-radius: 50%;
      border: 2px solid $brand-default;
      border-bottom-color: transparent;
      border-right-color: transparent;
      box-sizing: border-box;
      animation: rotate  1s infinite ease-in-out;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      height: 70%;
    }
  }

  &__name {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: white;
    //min-width: 200px;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--active {
    .layers-list-item__image {
      background-color: $brand-primary;
    }

    .layers-list-item__name {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
