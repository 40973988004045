@import "../../../assets/styles/variables";

.sub-menu-header {
  margin-top: 3px;
  height: 48px;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: $brand-default;
}