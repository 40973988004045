@import '../../assets/styles/variables';
@import "../../assets/styles/mixins";

.snapshots-menu {
  display: flex;
  flex-direction: column;
  color: #fff;

  &-header {
    padding-bottom: 31.5px;
    border-bottom: 1px solid $brand-smoke;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 10px;
      line-height: 1.3;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
    }
  }

  &-body {
    margin: 0;
    padding-right: 5px;
    min-height: 1vh;
    max-height: 80vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint(ipadPortrait) {
      max-height: 76vh;
    }

    @include breakpoint(ipadLandscape) {
      max-height: 70vh;
    }
  }

  &-footer {
    display: flex;
    position: absolute;
    bottom: 35px;
    left: 26px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: $brand-smoke;
  }

  .simplebar-wrapper {
    min-height: 1vh;
    max-height: 80vh;

    @include breakpoint(ipadPortrait) {
      max-height: 76vh;
    }

    @include breakpoint(ipadLandscape) {
      max-height: 70vh;
    }
  }
}
