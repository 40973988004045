@import "variables";

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 3%);
    border-color: darken($border, 25%);
    outline: none;
  }
  &:hover {
    color: $color;
    background-color: darken($background, 3%);
    border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 3%);
    background-image: none;
    border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($height, $width, $font-size, $line-height, $border-radius) {
  //padding: $padding-vertical $padding-horizontal;
  height: $height;
  width: $width;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

// Breakpoint mixin
@mixin breakpoint($point) {

  @if $point == maxHeight {
    @media (max-height: $break-780) { @content; }
  }

  @if $point == tablet {
    @media (max-width: $break-1366) { @content; }
  }

  @else if $point == desktop {
    @media (min-width: $break-1280) { @content; }
  }

    // Smartphones (portrait and landscape)
  @else if $point == mobile {
    @media only screen
    and (min-device-width : $break-320)
    and (max-device-width : $break-480) { @content; }
  }

    // Smartphones (landscape)
  @else if $point == mobileLandscape {
    @media only screen
    and (min-width : $break-321) { @content; }
  }

    // Smartphones (portrait)
  @else if $point == mobilePortrait {
    @media only screen
    and (max-width : $break-320) { @content; }
  }

    // iPads (portrait and landscape)
  @else if $point == ipad {
    @media only screen
    and (min-device-width : $break-700)
    and (max-device-width : $break-1024) { @content; }
  }

    // iPads (landscape)
  @else if $point == ipadLandscape {
    @media only screen
    and (min-device-width : $break-700)
    and (max-device-width : $break-1024)
    and (orientation : landscape) { @content; }
  }

    // iPads (portrait)
  @else if $point == ipadPortrait {
    @media only screen
    and (min-device-width : $break-700)
    and (max-device-width : $break-1024)
    and (orientation : portrait) { @content; }
  }

    // Desktops and laptops
  @else if $point == desktopLaptop {
    @media only screen
    and (min-width : $break-1224) { @content; }
  }

  @else if $point == desktopMedium {
    @media only screen
    and (min-width : $break-1640) { @content; }
  }

    // Large screens
  @else if $point == desktopLarge {
    @media only screen
    and (min-width : $break-1824) { @content; }
  }
}
