@import "~normalize.scss/normalize";
@import "~leaflet/dist/leaflet.css";
@import "~react-toastify/dist/ReactToastify.min.css";
@import "~simplebar/dist/simplebar.min.css";
@import '~rc-slider/assets/index.css';

@import "./assets/styles/variables";
@import "./assets/styles/app";

html, body, #root {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  *:not(input):not(textarea):not(select) {
    user-select: none;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Roboto", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: Roboto, "Roboto", Arial, sans-serif;
    font-variant-ligatures: no-common-ligatures;
    a, u {
      text-decoration: none;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "containers/LeafletMap/LeafletMap";

// Common components
@import "components/Common/Button/Button";
@import "components/Common/TextInput/TextInput";
@import "components/SuccessForm/SuccessForm";
@import "components/Sidebar/Sidebar";
@import "components/SignUp/SignUp";
@import "higher-order-components/SubMenu/SubMenu";
@import "higher-order-components/SubMenu/SubRoutesAnimation";
@import "higher-order-components/ProfileMenuScreen/ProfileMenuScreen";
@import "components/UserActionsList/UserActionsList";
@import "components/Common/SubMenuHeader/SubMenuHeader";
@import "components/Forms/FormSelect/FormSelect";
@import "components/ScreenLoader/ScreenLoader";
@import "components/Common/TextArea/TextArea";
@import "components/Common/CheckBox/CheckBox";
@import "components/Forgot/Forgot";
@import "components/ResetPassword/ResetPassword";
@import "components/ZoomControl/ZoomControl";
@import "components/Legend/Legend";
@import "components/DepthExpand/DepthExpand";

// Containers
@import "containers/Authorization/Authorization";
@import "containers/App";
@import "containers/Snapshots/Snapshots";
@import "containers/Profile/Profile";
@import "containers/Waypoints/Waypoints";
@import "containers/Profile/ProfileSubRoute";
@import "containers/Snapshots/SnapshotItem/SnapshotItem";
@import "containers/Support/Support";
@import "containers/Applications/Applications";
@import "containers/DepthView/DepthView";

// Waypoints
@import "components/WaypointsAccordion/WaypointsAccordion";
@import "components/WaypointsAccordion/WaypointsAccordionItem/WaypointsAccordionItem";
@import "containers/Waypoints/WaypointsSwitches/WaypointsSwitches";

// Layers list
@import "components/LayersList/LayersList";
@import "components/LayersList/LayersListItem/LayersListItem";
@import "components/LayersList/SelectFishLayer/SelectFishLayer";

// Timeline
@import "components/Timeline/Timeline";
@import "components/Timeline/TimelineControl/TimelineControl";
@import "components/Timeline/TimelineSlider/TimelineSlider";
@import "components/Timeline/TimelineSliderControl/TimelineSliderControl";
@import "components/Timeline/WaypointsCounter/WaypointsCounter";

// Profile Editing
@import "containers/ProfileEdit/ProfileEdit";
@import "components/ProfileEdit/ProfileSettings/ProfileSettings";
@import "components/ProfileEdit/ProfileInformation/ProfileInformation";
@import "components/ProfileEdit/EditAvatar/EditAvatar";
