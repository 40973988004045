@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.depth-expand {
  position: relative;
  display: block;
  width: 100%;
  height: 108px;
  padding: 15px 17px 16px;
  background-color: $gray;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: $brand-default;
  }

  &__expand-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    padding: 0;
    margin: 0;
    font-size: 10px;
    color: $brand-default;
    background-color: transparent;
    border: none;

    &::after {
      content: '+';
      display: inline-block;
      font-size: 16px;
      margin-left: 5px;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 49px;
    margin-top: 12px;
    overflow: hidden;

    &--lock::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 43px;
      background-image: url('./assets/images/icons/depth/lock.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 3;
    }

    canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: rotate(180deg);
    }
  }

  &__mock-relief {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: auto;
    left: -1px;
    bottom: -1px;
    z-index: 1;
  }

  &__body-line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(112, 112, 112, 0.2);
    z-index: 2;

    &:first-of-type, &:last-of-type {
      background-color: transparent;
    }
  }
}
