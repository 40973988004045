@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.waypoints-accordion-item {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;

  &__header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 0 0 16.2px;
    color: $brand-sapphire-color;
    box-sizing: border-box;
    cursor: pointer;

    @include breakpoint(tablet) {
      height: 40px;
      padding: 0 0 0 13px;
    }

    .icon {
      display: block;
      width: 14.3px;
      height: 21px;
      background-image: url("./assets/images/icons/waypoints/waypoints-icon.svg");
      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @include breakpoint(tablet) {
        width: 11.44px;
        height: 16.8px;
      }
    }

    .cross-icon {
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translate(0, -50%);
      padding: 10px;
      box-sizing: content-box;

      @include breakpoint(tablet) {
        right: 4.8px;
        padding: 8px;
      }

      &::after, &::before {
        width: 14px;
        background-color: $brand-sapphire-color;

        @include breakpoint(tablet) {
          width: 11.2px;
        }
      }
    }

    .lat-lng-value {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.17;
      margin-left: 15px;

      @include breakpoint(tablet) {
        font-size: 9.6px;
        margin-left: 12px;
      }

      span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 71px;
        height: 21px;
        margin-left: 5px;

        font-size: 12px;
        font-weight: normal;
        line-height: 1.17;

        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 3px;

        @include breakpoint(tablet) {
          width: 56.8px;
          height: 16.8px;
          margin-left: 4px;

          font-size: 9.6px;
        }
      }
    }
  }

  &__body {
    display: block;
    width: 100%;
    height: auto;
    font-size: 0; // For removing spaces
    padding: 16px 17px 0 16px;
    box-sizing: border-box;

    @include breakpoint(tablet) {
      padding: 12.8px 13.6px 0 12.8px;
    }
  }

  &:nth-of-type(1) {
    .waypoint-color {
      background-color: $waypointsBlueColor;
    }
  }

  &:nth-of-type(2) {
    .waypoint-color {
      background-color: $waypointsYellowColor;
    }
  }

  &:nth-of-type(3) {
    .waypoint-color {
      background-color: $waypointsRedColor;
    }
  }

  &:nth-of-type(4) {
    .waypoint-color {
      background-color: $waypointsGreenColor;
    }
  }

  &:nth-of-type(5) {
    .waypoint-color {
      background-color: $waypointsOrangeColor;
    }
  }
}

.waypoint-data {
  display: inline-block;
  width: 50%;
  margin: 0 0 17px;
  padding: 0 15px 0 0;
  box-sizing: border-box;

  @include breakpoint(tablet) {
    margin: 0 0 13.6px;
    padding: 0 12px 0 0;
  }

  &:nth-of-type(2n) {
    padding-right: 0;
    padding-left: 15px;

    @include breakpoint(tablet) {
      padding-left: 12px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__name {
    position: relative;
    min-width: auto;
    max-width: 95px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include breakpoint(tablet) {
      max-width: 76px;
    }
  }

  &__delimitter {
    display: block;
    flex: 1;
    max-width: 100%;
    min-width: 5px;
    width: auto;
    margin: 0 1px 0 4px;
    overflow: hidden;

    @include breakpoint(tablet) {
      min-width: 4px;
      margin: 0 1px 0 3.2px;
    }

    &::before {
      content: '.......................................';
      display: block;
      font-size: 10px;
      font-weight: bold;
      line-height: 1.1;
      min-width: 0;
      max-width: 100%;

      @include breakpoint(tablet) {
        font-size: 8px;
      }
    }
  }

  &__image {
    display: block;
    width: 13px;
    min-width: 13px;
    height: 13px;
    margin-right: 6px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @include breakpoint(tablet) {
      width: 10.4px;
      min-width: 10.4px;
      height: 10.4px;
      margin-right: 4.8px;
    }

    &--blank {
      background-color: $gray;
      border-radius: 50%;
    }
  }

  &__name, &__value {
    display: block;
    font-size: 10px;
    font-weight: bold;
    line-height: 1.1;
    text-shadow: 0 3px 20px $brand-sapphire-color;
    color: $brand-default;
    white-space: nowrap;

    @include breakpoint(tablet) {
      font-size: 8px;
      text-shadow: 0 2.4px 16px $brand-sapphire-color;
    }
  }
}
