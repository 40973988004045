@import "../../assets/styles/variables";

.depth-view {
  position: relative;
  height: 100%;
  width: 494px;
  top: 0;
  right: 0;
  background-color: $brand-depth;
  box-shadow: -10px 0 15px 0 rgba(0, 0, 0, 0.16);
  padding-top: 70px;
  box-sizing: border-box;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  &__close {
    position: absolute;
    top: 30px;
    right: 38px;
    width: 15px;
    height: 15px;

    &::after, &::before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      width: 21px;
      height: 2px;
      background-color: $brand-sapphire-color;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $brand-default;
  }

  &__canvas-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //filter: blur(10px);
      z-index: 0;
    }

    .heatmap-canvas {
      filter: blur(15px);
    }

    .canvas-depth {
      z-index: 1;
    }
  }

  &__depth-meter-line {
    position: relative;
    display: block;
    width: 100%;
    padding-left: calc(50% + 10px);
    font-size: 10px;
    line-height: 1.3;
    text-align: left;
    color: $brand-sapphire-color;
    border-bottom: 1px solid $brand-sapphire-color;
    text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.15);

    &:first-of-type {
      font-size: 0;
      line-height: 0;
      border-bottom: none;
    }
  }

  &__layers-list {
    position: absolute;
    top: 26px;
    left: 26px;
    z-index: 1;
  }

  &__icon {
    position: absolute;
    display: block;
    top: 25px;
    left: 0;
    transform: translate(-50%, -100%);
    z-index: 1;

    img {
      position: relative;
      width: 33px;
      z-index: 2;
    }
  }

  &__rotation {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    z-index: 3;
  }

  &__rotate-btn {
    position: relative;
    display: block;
    padding: 5px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: $brand-sapphire-color;
    text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.15);

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      transform: translate(0, -50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }

    &--left {
      padding-left: 34px;

      &::after {
        left: 12px;
        border-left: none;
        border-right: 12px solid $brand-sapphire-color;
      }
    }

    &--right {
      padding-right: 34px;

      &::after {
        right: 12px;
        border-right: none;
        border-left: 12px solid $brand-sapphire-color;
      }
    }
  }
}

.depth-legend {
  position: absolute;
  bottom: 28px;
  left: 24px;
  box-shadow: 0 3px 10px 0 $black;

  * {
    box-sizing: content-box;
  }

  .legend-content {
    margin-left: 0;
  }
}
