@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";


.legend {
  position: absolute;
  left: 100%;
  bottom: 84px;
  box-sizing: border-box;
  opacity: 0.4;
  transition: opacity 300ms ease-in-out;
  z-index: 1;

  &:hover {
    opacity: 1;
  }

  @include breakpoint(desktopMedium) {
    bottom: 124px;
  }

  &-content {
    margin-left: 20px;
    padding: 13px 13px 13px 15px;
    border-radius: $rounded;
    width: 290px;
    height: 64px;
    display: block;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 0 #000000;
  }

  &-header {
    margin-top: 2.2px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: $brand-default;
  }

  &-title {
    display: flex;
    align-items: center;

    &-img {
      width: 4.7px;
      height: 13px;
      min-width: 10px;
      margin-right: 4.4px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    &-name {
      min-width: auto;
      max-width: 125px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    span {
      font-weight: normal;
    }
  }

  &-body {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    height: 21px;

    canvas {
      width: 100%;
      height: 100%;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: left;
      color: $brand-default;
    }
  }
}
