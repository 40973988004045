/**
  Here are the variables for the project.
 */

$brand-primary: #00b1ff;
$brand-sapphire: #1B648F;
$brand-sapphire-opacity: rgba(27, 100, 143, 0.7);
$brand-sole: #287e8c;
$brand-default: #214360;
$brand-sand: #e4e4e4;
$brand-bg: #3A72A3;
$brand-smoke: #4E697F;
$brand-dark: #070d13;
$brand-depth: #3a76a9;
$border-color: rgba(255, 255, 255, 0.2);
$cross-icon-color: rgba(102, 120, 138, 1);
$check-green: #00ba64;
$uncheck-grey: #43505D;
$black: #000000;
$gray: #f5f5f5;
$nautical-miles: rgba(33, 67, 96, 0.7);

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $btn-primary-bg;

$btn-sole-color: #fff;
$btn-sole-bg: $brand-sole;
$btn-sole-border: $btn-sole-bg;

$brand-sapphire-color: #fff;
$brand-sapphire-bg: $brand-sapphire;
$brand-sapphire-border: $brand-sapphire;

$input-height: 50px;
$rounded: 3px;

/**
    breakpoint vars
 */
$break-320: 20em;
$break-321: 20.0625em;
$break-480: 30em;
$break-600: 37.5em;
$break-700: 43.750em;
$break-780: 48.75em;
$break-980: 61.25em;
$break-1024: 64em;
$break-1200: 75em;
$break-1224: 76.5em;
$break-1280: 80em;
$break-1366: 85.375em;
$break-1640: 102.5em;
$break-1824: 114em;

/**
   Waypoints accordion colors
 */
$waypointsBlueColor: #00b1ff;
$waypointsYellowColor: #ead916;
$waypointsRedColor: #f22f00;
$waypointsGreenColor: #01a02c;
$waypointsOrangeColor: #ff9512;

/**
  Timeline
 */
$timeline-max-tablet-width: 1640px;

/**
  Zoom control
 */
$zoomControlColor: #d4d8e3;
$zoomControlSliderCorol: rgb(215, 219, 228);
$zoomControlSliderBorder: #2a3f79;
