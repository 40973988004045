@import "../../../assets/styles/variables";

.timeline-slider {
  position: relative;
  display: block;
  width: 100%;

  .rc-slider {
    height: 7px;
    padding: 0;
    font-size: 0;
    box-sizing: content-box;

    @media screen and (max-width: $timeline-max-tablet-width) {
      display: none;
    }
  }

  .rc-slider-rail {
    height: 1px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: rgba(255, 255, 255, 0.75);
  }

  .rc-slider-step {
    top: 50%;
    transform: translate(0, -50%);
    height: 100%;
  }

  .rc-slider-dot {
    width: 1px;
    height: 7px;
    top: 50%;
    bottom: 0;
    transform: translate(0, -50%);
    margin-left: 0;
    border: 0;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.3);

    &[style="left: 0%;"], &[style="left: 33.3333%;"], &[style="left: 66.6667%;"], &[style="left: 100%;"],
    &:first-of-type, &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4) {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $brand-sapphire-color;
    }
  }

  .rc-slider-mark-text {
    margin-top: 7px;
    color: $brand-sapphire-color;
  }

  .rc-slider-handle {
    width: 7px;
    height: 24px;
    margin-left: -1px;
    margin-top: -9px;
    border: none;
    border-radius: 10px;
    background-color: $brand-sapphire-color;

    &:focus {
      outline: none;
      box-shadow: none;
      transition: none;
    }
  }
}
