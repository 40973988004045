@import "../../../assets/styles/variables";

.waypoints-switches {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $border-color;
  cursor: pointer;
  box-sizing: border-box;
  transition: border-bottom-color 300ms ease-in-out;

  &:hover {
    border-bottom-color: $brand-sapphire-color;
  }

  &__name {
    display: block;
    flex: 1;
    padding: 10.5px 0 12px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: $brand-sapphire-color;
  }

  &__delete {
    display: block;
    min-width: 10px;
    min-height: 10px;
    margin: 0 6px 1px 8px;
  }

  &__active {
    display: block;
    min-width: 10px;
    min-height: 12px;
    margin: 0 5px 3px 8px;
  }
}
