@import '../../assets/styles/variables';

.sub-menu {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  box-shadow: 15px 0 10px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(to bottom, #214360, #070d13);
  z-index: 3;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 27px 18.5px 27px 26.5px;
    box-sizing: border-box;
  }
}
