@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

$auth-form-layout-padding: 0 45px;
$auth-form-header-height: 67px;
$auth-form-header-font-size: 36px;
$auth-form-footer-height: 151px;
$auth-form-footer-font-size: 25px;
$auth-form-box-shadow:  0 10px 20px 0 rgba(0, 0, 0, 0.35);
$auth-font-size: 16px;
$logo-block-width: 650px;

.auth {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  font-size: $auth-font-size;
  overflow: hidden;
  z-index: 2;
}

.auth-splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, $brand-default, $brand-sole);
  opacity: 0.8;
  z-index: 2;
}

.auth-logo {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 13px;
  padding-left: 19px;


  > img {
    max-width: 100%;
    max-height: 170px;
    margin-bottom: 23px;
  }

  &-about {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    padding-bottom: 2px;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding-left: 176px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > span {
      max-height: 162px;
      min-height: 100px;
      padding-left: 1px;
      margin-bottom: 18px;
      margin-left: -176px;
      text-overflow: ellipsis;
      overflow: hidden;
      box-sizing: content-box;
    }

    @include breakpoint(ipadPortrait) {
      padding-left: 90px;
    }

    @include breakpoint(ipadLandscape) {
      padding-left: 110px;
    }
  }

  @include breakpoint(ipadPortrait) {
    width: 360px;
  }

  @include breakpoint(ipadLandscape) {
    width: 390px;
  }

  @include breakpoint(desktop) {
    width: 450px;
  }

  @include breakpoint(desktopLarge) {
    width: 650px;
  }
}

.auth-form {
  //margin: 78px 45px 18px 16px;
  margin-top: 45px;
  margin-left: 9px;
  width: 578px;
  height: 589px;
  background: #fff;
  font-weight: bold;
  border-radius: 5px 5px 8px 8px;
  box-shadow: $auth-form-box-shadow;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:before {
    content: "";
    width: 100%;
    height: 21px;
    background-color: $brand-default;
    position: relative;
    top: -20px;
    border-radius: 5px 5px 0 0;
  }

  .auth-form-layout {
    padding: $auth-form-layout-padding;
    display: flex;
    flex-direction: column;
  }

  .auth-form-header {
    height: $auth-form-header-height;
    font-size: $auth-form-header-font-size;
    position: relative;
    bottom: 15px;
    color: $brand-default;
    border-bottom: 1px solid $brand-sand;
    justify-content: flex-start;
  }

  &-inputs {
    height: 230px;
    padding-bottom: 32px;
    font-weight: 400;
    color: $brand-default;
  }

  &-sign-up-inputs {
    height: auto;
    font-weight: 400;
    color: $brand-default;

    @include breakpoint(ipadLandscape) {
      margin: 15px 0 30px;
    }
  }

  &-sign-up-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }


  .forgot-submit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -4px;

    .forgot {
      width: 115px;
      height: 19px;
      font-size: 14px;
      line-height: 1.36;
      color: $brand-default;

      &:hover {
        color: $brand-primary;
        cursor: pointer;
      }
    }
  }

  .register-block {
    background-color: $brand-bg;
    height: $auth-form-footer-height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    > span {
      color: #fff;
      font-weight: normal;
      font-size: $auth-form-footer-font-size;
    }
  }


  @include breakpoint(ipadPortrait) {
    width: 360px;
    height: 550px;
    margin: 20px 45px 5px 16px !important;
    &:before {
      top: -20px;
    }
  }

  @include breakpoint(ipadLandscape) {
    width: 400px;
    height: 535px;
    margin: 20px 45px 5px 16px !important;
    &:before {
      top: -18px;
    }
  }

  @include breakpoint(desktopLaptop) {
    width: 462px;
    height: 555px;
    &:before {
      top: -20px;
    }
  }

  @include breakpoint(desktopLarge) {
    width: 589px;
    height: 580px;
  }
}
