@import "../../../assets/styles/variables";

.text-area-wrapper {
  margin-top: 11px;
}

.text-area {
  padding-top: 15px;
  height: 205px;
  margin-bottom: 0;

  &-error {
    top: 15px;
  }
}