@import "../../../assets/styles/variables";

.timeline-slider-control {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: -14px;
  left: 50%;
  transform: translate(-50%, -100%);

  @media screen and (max-width: $timeline-max-tablet-width) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  &__current-timestamp {
    display: block;
    width: 179px;
    height: 24px;
    margin: 0;
    line-height: 24px;

    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: $brand-sapphire-color;

    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
  }

  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 11px;
    height: auto;
    padding: 7px;
    border: none;
    margin: 0;
    background: none;
    cursor: pointer;
    box-sizing: content-box;

    img {
      width: 100%;
    }
  }
}
