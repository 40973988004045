@import "../../../assets/styles/variables";

.form-control {
  box-shadow: none;
  width: calc(100% - 35px);
  border-radius: 3px;
  height: $input-height;
  border: 1px solid $brand-sand;
  background: $brand-sand;
  margin-bottom: 20px;
  padding: 0 17px;

  &:focus, &:active {
    background: none;
  }
}

.input-not-empty {
  background: none;
}

.has-error {
  color: red;
  font-size: 16px;
  position: absolute;
  margin-top: -25px;
}