@import '../../assets/styles/variables';

.support-screen {
  position: fixed;
  display: block;
  top: 0;
  left: 80px;
  width: calc(100% - 80px);
  height: 100%;
  background-color: #fff;
  z-index: 2;
  color: $brand-default;

  &-content {
    height: 100%;
    box-sizing: border-box;
    padding: 54px 38.5px 53px 55px;

    .close {
      width: 21px;
      height: 21px;
      float: right;
      right: 31px;
      top: 25px;
      position: fixed;
      margin: 0;
      padding: 0;
      overflow: hidden;
      border-radius: 2px;
      border: solid 2px #fff;
      transition: all 0.3s ease-out;
      cursor: pointer;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      &::before,
      &::after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 21px;
        width: 2px;
        background-color: $brand-default;
      }

      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &-inputs {
    margin: 45px 0;
    width: 500px;
  }
}
