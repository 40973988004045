@import '../../assets/styles/variables';

.sidebar-wrapper {
  position: relative;
  height: 100%;
  width: 80px;
  background: linear-gradient(to top, #214360, #287e8c);
  z-index: 0;
}

.sidebar-menu-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  a:first-child {
    min-height: 60px;
    height: auto !important;
    margin-top: 6px;

    :first-child {
      margin-bottom: 3px;
    }
  }

  .sidebar-avatar-wrapper {
    margin: 0;
    height: 100%;

    .sidebar-avatar {
      border: 2px solid white;
      width: 37.6px;
      height: 37.6px;
      margin: 0 auto;
      border-radius: 50%;

      img {
        width: inherit;
        height: inherit;
        border-radius: inherit;
        margin: inherit;
      }
    }
  }

  .sidebar-menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
    width: 100%;
    height: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: none;
    color: white;

    &--without-icon {
      display:block;
      word-wrap:break-word;
      width: calc(100% - 10px);
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: center;
      color: white;
    }

    &.active {
      background-color: $brand-default;
    }

    &:hover {
      cursor: pointer;
      background-color: $brand-default;
    }
  }

  .sidebar-bottom-logo {
    position: absolute;
    bottom: 4px;
    left: 1px;
    height: 70px;
    width: 71px;

    &:hover {
      cursor: default;
      background: none;
    }
  }

  .sidebar-menu-icon {
    margin: 0 20px 5px;
    width: 37.6px;
  }

  .user-avatar {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
