@import "../../../assets/styles/variables";

.waypoints-counter {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 100%;
  top: 0;
  right: 0;
  transform: translate(100%);
  background-color: $nautical-miles;

  @media screen and (max-width: $timeline-max-tablet-width) {
    width: 100px;
  }

  &__icon {
    display: block;
    width: auto;
    height: 42px;
    margin-right: 19px;

    @media screen and (max-width: $timeline-max-tablet-width) {
      height: 30px;
      margin-right: 14px;
    }
  }

  &__value {
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: normal;
    color: $brand-sapphire-color;
  }
}
