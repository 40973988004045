@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.forgot-form {
  height: 300px !important;

  .auth-form-inputs {
    height: 150px;
  }

  @include breakpoint(ipadPortrait) {
    width: 360px !important;
    margin: 20px 45px 5px 16px !important;

    .auth-form-header {
      font-size: 30px !important;
    }
    .error {
      font-size: 14px;
    }
  }

  @include breakpoint(ipadLandscape) {
    width: 400px !important;
    margin: 20px 45px 5px 16px !important;

    .auth-form-header {
      font-size: 30px !important;
    }

    > .auth-form-header {
      justify-content: center !important;
    }

    .auth-form-sign-up-buttons {
      padding-bottom: 100px;
    }
  }

  @include breakpoint(desktopLaptop) {
    width: 460px;
    margin: 50px 45px 50px 16px !important;
  }

  @include breakpoint(desktopLarge) {
    width: 589px !important;
    margin: 45px 0 50px 9px !important;
  }
}