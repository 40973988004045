@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";


.success-form {
  margin: 0 20px;
  width: 460px;
  height: 280px;
  background: #fff;
  font-weight: bold;
  border-radius: 5px 5px 8px 8px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35);
  align-self: center;
  display: flex;
  flex-direction: column;
  color: $brand-default;

  &:before {
    content: "";
    width: 100%;
    height: 21px;
    background-color: $brand-default;
    position: relative;
    top: -20px;
    border-radius: 5px 5px 0 0;
  }

  .success-form-layout {
    padding: 0 45px;
    display: flex;
    flex-direction: column;
  }

  &-header {
    height: 67px;
    font-size: 36px;
    padding-bottom: 15px;
    color: $brand-default;
    border-bottom: 1px solid $brand-sand;
    justify-content: center;
  }

  &-body {
    margin: 20px 0;

    > .btn {
      margin-top: 30px;
      align-self: flex-end;
    }
  }


  @include breakpoint(ipadPortrait) {
    width: 430px;
  }

  @include breakpoint(desktop) {
    width: 460px;
  }
}

.success-text {
  color: $brand-primary;
  margin-top: .25rem;
  font-size: 16px;
}
