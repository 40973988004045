@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.checkbox-input-wrapper {
  position: relative;

  input {
    visibility: hidden;
  }
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 10.5px;

  &-img {
    align-self: center;
    width: 13px;
    height: 12px;
  }

  &-lock-icon {
    position: absolute;
    right: 0;
    width: 13px;
    height: 12px;
  }
}

.checkbox-checked {
  .checkbox-tick {
    display: block;
  }
}

.checkbox-bar, .checkbox-bar-checked {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 29px;
  height: 15px;
  margin: auto;
  border-radius: 15px;
  background-color: $uncheck-grey;

  &-checked {
    background-color: $check-green;
  }
}

.checkbox-circle, .checkbox-circle-checked {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -12px;
  right: 0;
  width: 11px;
  height: 11px;
  margin: auto;
  border-radius: 11px;
  background-color: white;

  &-checked {
    left: 0;
    right: -15px;
  }
}

.checkbox-label {
  padding-left: 28px;
  display: flex;
  align-items: center;
}

.checkbox-span {
  padding-left: 5.5px;
  white-space: nowrap;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}