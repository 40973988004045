@import '../../assets/styles/variables';
@import "../../assets/styles/mixins";

.sub-route {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-up-content {
    display: flex;
    flex-direction: column;
  }

  &-down-content {
    display: flex;
    align-items: center;
  }

  @include breakpoint(ipadPortrait) {
    .edit-avatar, .edit-avatar-body {
      width: 270px;
    }
  }
}